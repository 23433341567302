
.content{
    font-weight: 600;
}

.fre-splash{
    z-index: 1002 !important;
    animation: fadeIn .5s ease;
}

.carousel{
    width: 720px;
    height: 330px;
    padding: 20px;
    position: relative;
    top: -20px;
    color: #f9f9f9;

    .leftButton, .rightButton{
        height: 36px;
        min-width: 36px;
        position: absolute;
        z-index: 1;
        top: calc(50% - 8px);
        border-radius: 36px;
        padding: 0;
        opacity: .7;
        background: #444;
        cursor: pointer;
        transform: scale(.85);
        &:hover{
            opacity: 1;
        }
        border: 1px solid rgba(white, .2);
        i{
            position: relative;
            margin: 0;
            top: 2px;
            left: 1px;
            font-weight: 600;
            color: white;
        }
    }

    .leftButton{
        left: 20px;
        i{
            left: -1px;
        }
    }

    .rightButton{
        right: 20px;
        .icon16{
            position: relative;
            transform: rotate(-90deg) scale(1.4);
            left: 1px;
        }
    }
    
    @keyframes slideRight {
        from {
          transform: translateX(-32px);
        }
        to {
          transform: translateX(0px);
        } 
    }
    @keyframes slideLeft {
        from {
          transform: translateX(32px);
        }
        to {
          transform: translateX(0px);
        } 
    }
    @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1
        } 
    }

    .page{
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 40px);
        height: 100%;
        padding: 20px;
        display: none;
        &.shown{
            display: block;
        }
        &.left{
            animation: slideLeft 0.8s cubic-bezier(0.1, 0.9, 0.2, 1) forwards, fadeIn 0.8s cubic-bezier(0.1, 0.9, 0.2, 1) forwards;
        }
        &.right{
            animation: slideRight 0.8s cubic-bezier(0.1, 0.9, 0.2, 1) forwards, fadeIn 0.8s cubic-bezier(0.1, 0.9, 0.2, 1) forwards;
        }
        .text{
            text-align: center;
            padding: 0 60px;
        }
    }

    .page1img{
        width: 100%;
        height: 70%;
        background-size: 42%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(./page1.svg);
    }

    .page2img{
        width: 100%;
        height: 66%;
        background-size: 35%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(./page2.svg);
    }

    .page2{
        .text{
            margin-top: 16px;
            padding: 0 60px;
        }
    }

    .page3{
        .page3imgaWrapper, .page3imgbWrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            border-radius: 3px;
            border: 2px solid transparent;
            width: 33%;
            box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
            transform: scale(.9);
            margin-top: 20px;
            margin-bottom: 8px;
            &:hover{
                cursor: pointer;
                border: 2px solid #60aaff;
            }
            .text{
                width: 78%;
                font-size: 15px;
                opacity: .92;
                position: relative;
                top: -48px;
                height: 0;
                margin-top: 20px;
                padding: 0;
            }
        }
        .page3imgaWrapper{
            margin-right: 28px;
        }
        &.shown{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .images{
            width: 100%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
        }
        .page3aimg, .page3bimg{
            width: 190px;
            height: 190px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
        .page3aimg{
            background-image: url(./page3a.svg);
        }
        .page3bimg{
            background-size: 92%;
            position: relative;
            top: 4px;
            background-image: url(./page3b.svg);
        }
        .text{
            position: relative;
            top: 12px;
        }
    }

    .dots{
        position: absolute;
        bottom: -20px;
        left: calc(50% - 32px);
        display: flex;
        transform: scale(.75);
        .dot{
            width: 16px;
            height: 16px;
            border-radius: 16px;
            cursor: pointer; 
            margin-right: 8px;
            border: 1px solid rgba(white, .2);
            &.filled{
                background: #60aaff;
            }
        }
    }
}

.fre-close{
    background: none;
    color: #999;
    position: absolute;
    cursor: pointer;
    right: -12px;
    top: 8px;
    border: none;
    font-size: 16px;
    &:hover{
        color: white;
    }
}