@import "../../../theme/theme.scss";

.relationship-key {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background-color: $backgroundColor2;
  border-radius: 8px;
  min-width: 125px;
  z-index: 1;
  .rels-wrap {
    .rel-key {
      font-size: 12px;
      color: $textColorLight2;
      display: flex;
      align-items: center;
      display: flex;
      margin-bottom: 3px;
      svg {
        width: 28px;
        margin-right: 10px;
      }
      .rel-title {
        min-width: 75px;
        margin-right: 5px;
      }
      .rel-toggle {
        margin-bottom: 0;
        button {
          font-size: 16px;
          width: 32px;
          height: 16px;
          border-radius: 8px;
          span {
            width: 10px;
            height: 10px;
            background: #fff;
          }
        }
        &.is-checked {
          button {
            background-color: $buttonColor;
            span{
              background: var(--toggle-knob-active);
            }
          }
        }
      }
    }
  }
}
