@import "../../theme/theme.scss";

.mgv-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;

  .model-detail {
    position: relative;
    height: 100%;
    .detail-toggle {
      position: absolute;
      top: 15px;
      left: -30px;
      height: 30px;
      width: 30px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $backgroundColor2;
      cursor: pointer;
    }
  }

  &.md-open {
    .model-detail {
      width: 24%;

      .dragable {
        width: 2px;
        height: 100%;
        position: absolute;
        left: -2px;
        top: 0;
        background-color: $backgroundColor2;
        transition: background-color 200ms ease;

        &:hover {
          cursor: col-resize;
          background-color: #3f3f3f;
        }
      }
    }
  }

  &.md-closed {
    .model-detail {
      width: 0;
    }
  }
}

.model-graph {
  flex: 1;
  position: relative;
  .gc-wrap {
    height: calc(100% - 8px);
    transition: height 0.2s ease-out;
    .relationship-key {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 10px;
      background-color: $backgroundColor2;
      border-radius: 8px;
      min-width: 125px;
    }
  }
  .gc-filter {
    flex: 0;
    position: relative;
    background-color: $backgroundColor2;
    .gc-controls {
      position: absolute;
      top: -136px;
      left: 20px;
    }
    .gc-controls-with-error {
      position: absolute;
      left: 20px;
      top: -150px;
    }
    .gc-filter-contents {
      height: 8px;
      transition: height 0.2s ease-out;
      > div {
        padding: 10px;
      }
    }
  }
  .controls_buttonGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    background-color: $backgroundColor2;
    border-radius: 5px;
    i {
      font-size: 14px;
      color: white;
    }
  }
  .controls_singleButton {
    background-color: $backgroundColor2;
    border-radius: 5px;
    margin-bottom: 8px;
    i {
      font-size: 18px;
      color: white;
    }
  }
  .filter_button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 18px;
  }
  .control-loadButtons {
    height: 30px;
    font-weight: 200;
    padding: 0;
  }
  &.open {
    .gc-filter {
      .gc-filter-contents {
        height: 120px;
      }
    }
    .gc-wrap {
      height: calc(100% - 200px);
    }
  }

  .mgv-filter-wrap {
    position: relative;
    .filter-add-hitbox {
      position: absolute;
      top: 4px;
      right: 7px;
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .filter-input {
    width: 25%;
    max-width: 300px;
    min-width: 130px;
    margin: 0px 10px 10px 0px;
    .configuration-input {
      margin-right: 10px;
      label {
        font-size: 12;
        font-weight: 500;
        color: $textColorLight1;
        white-space: nowrap;
        margin-left: 3px;
      }
    }
  }

  .filter-options {
    display: flex;
  }

  .highlight-section {
    display: flex;
    padding-top: 10px;
  }

  .filter-terms {
    padding: 0 16px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .filter-toggle {
      margin-bottom: 0;
      height: 100%;
      margin-right: 5px;
      display: flex;
      align-items: center;
      .ms-Toggle-background {
        border-color: transparent;
        background: #7E7E7E;
        .ms-Toggle-thumb {
          background: white;
          height: 8px;
          width: 8px;
          font-size: 6px;
        }
      }
      &.is-checked {
        .ms-Toggle-background {
          background: #084772;
        }
      }
    }
    .filter-term {
      margin-right: 12px;
      position: relative;
      height: 22px;
      margin-bottom: 12px;
      .filter-buttons {
        display: flex;
      }
      .more-icon {
        &:hover {
          background: transparent;
        }
      }
      .term-bar {
        height: 22px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        background: $inputOutlineColor;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        min-width: 105px;
        align-items: center;
        &.active {
          background: $buttonColor;
        }
      }
      .term-options {
        .ms-Checkbox-text {
          color: $textColorLight2;
          font-size: 10px;
          margin-left: 0;
        }
        .ms-Checkbox-checkbox {
          height: 15px;
          width: 15px;
        }
        .ms-Checkbox-label {
          align-items: center;
          margin-left: 5px;
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }
      .term-menu {
        position: absolute;
        background: $backgroundColor2;
        color: $textColorLight2;
        font-size: 10px;
        padding: 8px;
        border: 1px solid $outlineColor2;
        border-radius: 5px;
        bottom: 25px;
        left: 0;
        .term-menu-item {
          display: flex;
          white-space: nowrap;
          align-items: center;
          margin-right: 5px;
        }
      }
      .ms-Checkbox-label {
        align-items: center;
        margin-left: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }
}
