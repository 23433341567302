@import "../../theme/theme.scss";

.pi-patch-modal {
    .patch-json-data{
        max-height: 60vh;
        overflow: auto;
        margin: 16px 0px;
    }
    h2 {
        font-weight: 400;
        margin-bottom: 10px;
        margin-top: 0;
    }
    .modal-button {
        width: 100px;
        margin-right: 10px;
        color: $textColorLight1;
        font-weight: 600;
        border: none;
        &.close-button {
        float: right;
        background: $buttonColor;
        }
    }
}

.property-inspector-container{
    height: calc(100% - 8px);
    padding: 8px 16px 20px 16px;
    overflow: hidden;
}


.cb-data-history-explorer-root {
    .ms-SelectionZone {
        padding: 0;
    }
}